<template>
  <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
    <v-card>
      <v-card-title class="headline">
        LOGOUT?
      </v-card-title>
      <v-card-text>
        Are your sure you want to logout?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          :loading="loggingOut"
          @click="logout"
        >
        Yes
        </v-btn>
        <v-btn
        color="green darken-1"
        text
        @click="dialog = false"
        >
        No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'LogoutDialog',
  data () {
    return {
      dialog: false,
      loggingOut: false,
    }
  },
  methods: {
    openDialog () {
      this.dialog = true
    },
    logout () {
      this.loggingOut = true
      this.$store.dispatch('auth/logout')
      window.location.reload()
    },
  },
}
</script>
